<template>
  <v-container class="mt-8">
    <v-row justify="center">
      <v-col md="8">
        <v-card flat outlined>
          <v-row justify="start">
            <v-col md="2" class="pa-0"></v-col>
            <v-col class="pb-0" md="4"
              ><v-text-field
                dense
                outlined
                class="mt-2"
                :label="$t('hr.vacation.name')"
                v-model="name"
              ></v-text-field
            ></v-col>
          </v-row>
          <v-row justify="start" class="ma-0 mt-2">
            <v-col md="2" class="pa-0"></v-col>
            <v-col md="3">
              <v-select
                outlined
                :label="$t('hr.vacation.type')"
                :items="['ساعي', 'يومي']"
                variant="outlined"
                class="pa-0"
                v-model="period"
              ></v-select>
            </v-col>
            <v-col md="5">
              <v-select
                outlined
                :label = "$t('hr.vacation.discount')"
                :items="['تخصم من الراتب', 'تخصم من الاجازات المستحقة']"
                variant="outlined"
                v-model="DiscountType"
              ></v-select>
              <v-text-field
                v-if="DiscountType == 'تخصم من الراتب'"
                outlined
                append-icon="mdi-percent"
                v-model="discount_percent"
                placeholder="نسبة الخصم"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card>
        <v-row justify="center" class="mt-6">
          <v-col cols="2">
            <v-btn color="primary" class="save-button" @click="submit">{{
              $t("save")
            }}</v-btn>
          </v-col>
          <v-col cols="2">
            <v-btn @click="cancel" color="error" class="cancel-button">{{
              $t("cancel")
            }}</v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import axios from "axios";
export default {
  created() {
    if (this.$route.params.id != "CreateNewVacation") {
      axios.get(`hr/leave/type/${this.$route.params.id}`).then((res) => {
        this.name = res.data.data.name;
        if (res.data.data.discount_type == "justified") {
          this.DiscountType = "تخصم من الاجازات المستحقة";
        } else {
          this.DiscountType = "تخصم من الراتب";
        }
        if (res.data.data.type == "hourly") {
          this.period = "ساعي";
        } else {
          this.period = "يومي";
        }
        this.discount_percent = res.data.data.discount_percent;
      });
    }
  },
  data() {
    return {
      DiscountType: "",
      discount_percent: "",
      name: "",
      period: "",
    };
  },
  methods: {
    submit() {
      if (this.DiscountType != "تخصم من الاجازات المستحقة") {
        this.DiscountType = "leaves";
      } else {
        this.DiscountType = "justified";
      }
      if (this.period == "يومي") {
        this.period = "daily";
      } else {
        this.period = "hourly";
      }
      if (this.$route.params.id == "CreateNewVacation") {
        axios
          .post("/hr/leave/type", {
            name: this.name,
            type: this.period,
            discount_type: this.DiscountType,
            discount_percent: this.discount_percent,
          })
          .then((res) => {
            if (res.status == 200) {
              this.$router.push("/HR/initiate");
              this.$Notifications(
                "تمت اضافة اجازة جديدة بنجاح",
                { rtl: true, timeout: 2500 },
                "success"
              );
              this.name = "";
            }
          });
      } else {
        axios
          .put(`/hr/leave/type/${this.$route.params.id}`, {
            name: this.name,
            type: this.period,
            discount_type: this.DiscountType,
            discount_percent: this.discount_percent,
          })
          .then((res) => {
            if (res.status == 200) {
              this.$router.push("/HR/initiate");
              this.$Notifications(
                "تم تعديل اجازة بنجاح",
                { rtl: true, timeout: 2500 },
                "success"
              );
              this.name = "";
            }
          });
      }
    },
    cancel() {
      this.$router.push("/HR/initiate");
    },
  },
};
</script>